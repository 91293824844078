<template>
  <div
    class="markdown-content"
    v-html="compiledMarkdown"
  />
</template>

<script>
import marked from 'marked';
import { computed } from '@vue/reactivity';

marked.use({ renderer: { image }});

export default {
  name: 'Markdown',
  props: {
    content: String,
    parser: Function,
  },
  setup(props) {
    const compiledMarkdown = computed(() => {
      const html = marked(props.content ?? '', { xhtml: true });

      return props.parser ? props.parser(html) : html;
    });

    return {
      compiledMarkdown,
    };
  },
};

function image(href, title, text) {
  href = `https://${process.env.VUE_APP_STRAPI}${href}`;
  if (href === null) {
    return text;
  }

  let out = '<img src="' + href + '" alt="' + text + '"';
  if (title) {
    out += ' title="' + title + '"';
  }
  out += this.options.xhtml ? '/>' : '>';

  return out;
}
</script>

<style lang="less">
.markdown-content {
  line-height: 22px;
  font-size: 14px;
  text-align: justify;

  p {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    max-width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 14px;
    font-weight: bold;
    line-height: 1.2;
  }
  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 10px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 1em;
  }
  ol {
    list-style-type: decimal;
    margin-left: 1em;
  }
  
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 14px;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  blockquote {
    margin: 0 0 14px;
  }
}
</style>
