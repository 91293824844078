privacy<template>
  <div class="privacy">
    <Markdown :content="data.content" />
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import request from '@/utils/request';
import Markdown from '@/components/Markdown';

export default {
  components: {
    Markdown,
  },
  setup() {
    const data = reactive({});
    const fetching = ref(false);

    const getData = async () => {
      try {
        fetching.value = true;
        const res = await request.strapi.get('h-5-privacy-protocol');
        const json = res.data;
        Object.assign(data, json);
        document.title = json.title;
      } catch (error) {
        console.error(error);
      } finally {
        fetching.value = false;
      }
    };

    document.title = '隐私权限政策';
    getData();

    return {
      data,
      fetching,
    };
  },
};
</script>

<style lang="less" scope>
.privacy {
  padding: 15px;
}
</style>
